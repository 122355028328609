:root {
  --animate-delay: 300ms;
  --animate-duration: 700ms;
}

body[data-scroll-locked='true'] {
  overflow: hidden !important;
  overscroll-behavior: contain;
  position: relative !important;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0px !important;
}
